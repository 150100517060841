import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, SyntheticEvent, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  StyledConflictListItem,
  StyledConflictListItemText,
  StyledConflictListItemTextWrapper,
  StyledConflictListItemWrapper,
  StyledConflictQuantityLabel,
} from './ConflictListItem.styles';
import AddToCart from '@molecules/AddToCart/AddToCart';
import KEYS from '@helpers/keys';
import Config from '@config';
import { AxfoodReplacementProductViewModel, UnitData } from '@occ/api-client';
import WillysImagePlaceholder from '@icons/non-standard/WILLYS-IMAGE-PLACEHOLDER-72px.svg';
import useResponsive from '@hooks/useResponsive';

export type ConflictListItemType = 'conflicting' | 'replaceable' | 'non-replaceable';

const ImageWrapper = styled.div`
  min-width: ${rem(68)};
  min-height: ${rem(68)};
  width: ${rem(68)};
  height: ${rem(68)};
`;

interface Props {
  product: AxfoodReplacementProductViewModel & { pickUnit?: UnitData };
  editable?: boolean;
  variant: ConflictListItemType;
  selected?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onQuantityChange?: (qty: number) => void;
  mouseEnter?: (e: MouseEvent<HTMLDivElement>) => void;
  mouseLeave?: (e: MouseEvent<HTMLDivElement>) => void;
  pickQuantity: number;
  availableQuantity: number;
  partialOutOfStock?: boolean;
  addToCartDisabled?: boolean;
}

const ConflictListItem = ({
  product,
  editable = false,
  variant,
  selected = false,
  onClick,
  onQuantityChange,
  mouseEnter,
  mouseLeave,
  pickQuantity,
  availableQuantity,
  addToCartDisabled = false,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('conflictListItem');
  const { isMobile, fromTabletPortrait } = useResponsive();
  const { productBasketType = {}, name, productLine2, potentialPromotions, price, depositPrice, image } = product;
  const recycleText = depositPrice ? t('depositPrice') : '';
  const unitTypes = {
    VIKT: 'kg',
    ST: 'st',
  };

  const unit = product?.pickUnit
    ? product?.pickUnit?.code
    : product?.productBasketType?.code === 'ST'
    ? 'pieces'
    : 'kilogram';

  const [quantity, setQuantity] = useState(product.replacementQty || 0);
  const [isActive, setIsActive] = useState(false);

  const outOfStockText =
    pickQuantity > 1
      ? `${t(variant === 'non-replaceable' ? 'partiallyRemoveItems' : 'partiallyOutOfStock', {
          amountToReplace: pickQuantity - availableQuantity,
          totalAmount: pickQuantity,
          unit: unitTypes[productBasketType.code as keyof typeof unitTypes],
        })} `
      : '';

  const onClickHandler = (newValue: number, e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    e?.preventDefault();

    if (newValue >= Config.PRODUCT.MIN_AMOUNT) {
      setQuantity(newValue);
      if (onQuantityChange) onQuantityChange(newValue);
    }
  };
  const setCaretPos = (e: KeyboardEvent<HTMLInputElement>) => {
    const lastDigitPosition = inputValue(e).toString().length;
    inputRef.current?.setSelectionRange(lastDigitPosition, lastDigitPosition);
  };
  const onKeyUpHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KEYS.ENTER:
      case KEYS.ESCAPE:
        return inputRef.current?.blur();
      case KEYS.UP:
        if (isActive) onClickHandler(+inputValue(e) + 1);
        break;
      case KEYS.DOWN:
        if (isActive) onClickHandler(+inputValue(e) - 1);
        break;
      case KEYS.LEFT:
      case KEYS.RIGHT:
        break;
      default:
        setCaretPos(e);
    }
    return false;
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    const newQuantity = +parseFloat(e.currentTarget.value).toFixed(1);

    if (onQuantityChange) onQuantityChange(newQuantity);

    setIsActive(false);
  };
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +inputValue(e);
    if (value > Config.PRODUCT.MAX_AMOUNT || value < Config.PRODUCT.MIN_AMOUNT) return;
    setQuantity(value);
  };
  const inputValue = (e: SyntheticEvent<HTMLInputElement>) => {
    if (unit === 'kilogram') {
      const forceDot = e.currentTarget.value.replace(',', '.');
      return parseFloat(forceDot.replace(/[^0-9.]/g, ''));
    }
    return e.currentTarget.value.replace(/[^0-9]/g, '');
  };
  const markQuantity = (e: FocusEvent<HTMLInputElement>) => {
    const lastDigitPosition = inputValue(e).toString().length;
    inputRef.current?.setSelectionRange(0, lastDigitPosition);
  };
  const onFocusHandler = (e: FocusEvent<HTMLInputElement>) => {
    setIsActive(true);
    markQuantity(e);
  };

  return (
    <StyledConflictListItem
      variant={variant}
      isSelected={selected}
      onClick={onClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      disabledAddToCart={!onClick}
    >
      <StyledConflictListItemWrapper>
        {addToCartDisabled && (
          <>
            <ImageWrapper>
              {image?.url ? (
                <Image unoptimized src={image?.url} alt={name} layout="fixed" width={68} height={68} />
              ) : (
                <WillysImagePlaceholder />
              )}
            </ImageWrapper>
            <StyledConflictListItemTextWrapper>
              <StyledConflictListItemText type="subhead">{name}</StyledConflictListItemText>
            </StyledConflictListItemTextWrapper>
          </>
        )}

        {!addToCartDisabled && (
          <>
            <ImageWrapper>
              {image?.url ? (
                <Image unoptimized src={image?.url} alt={name} layout="fixed" width={68} height={68} />
              ) : (
                <WillysImagePlaceholder />
              )}
            </ImageWrapper>
            <StyledConflictListItemTextWrapper>
              {isMobile && (
                <StyledConflictListItemText type="body" size="small" color="red">
                  {outOfStockText}
                </StyledConflictListItemText>
              )}
              <StyledConflictListItemText type="subhead">{name}</StyledConflictListItemText>
              <StyledConflictListItemText type="detail" size="small" color="gray">
                {productLine2}
              </StyledConflictListItemText>
              {potentialPromotions?.length > 0 ? (
                <StyledConflictListItemText type="subhead" color="red">
                  {`${potentialPromotions[0].price?.formattedValue} ${recycleText}`}
                </StyledConflictListItemText>
              ) : (
                <StyledConflictListItemText type="subhead">{`${price} ${recycleText}`}</StyledConflictListItemText>
              )}
            </StyledConflictListItemTextWrapper>
            {fromTabletPortrait && (
              <StyledConflictQuantityLabel type="body" size="small" color="red">
                {outOfStockText}
              </StyledConflictQuantityLabel>
            )}
            {editable && onQuantityChange && (
              <AddToCart
                inputRef={inputRef}
                onBlurHandler={onBlurHandler}
                onChangeHandler={onChangeHandler}
                onFocusHandler={onFocusHandler}
                onKeyUpHandler={onKeyUpHandler}
                onClickHandler={onClickHandler}
                isActive={isActive}
                currentQuantity={quantity}
                unit={unit}
                incrementValue={product.incrementValue}
                outOfStock={product.outOfStock}
                theme="secondary"
                size="medium"
                ignoreCartIsUpdating
              />
            )}
          </>
        )}
      </StyledConflictListItemWrapper>
    </StyledConflictListItem>
  );
};

export default ConflictListItem;
