import { MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@hooks/useAppDispatch';
import useResizeObserver from '@react-hook/resize-observer';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import ProductListItem from '@molecules/ProductListItem/ProductListItem';
import EmptyCartModal from '@organisms/EmptyCartModal/EmptyCartModal';
import { makeProductEntries } from '@helpers/wishlistProductEntries';
import paths, { LoginQueryParams } from '@constants/paths';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import Config from '@config';
import trackShoppingList from '@helpers/analyticsHelpers/trackShoppingList';
import {
  StyledAddToListButton,
  StyledCartPreview,
  StyledCartPreviewBodyWrapper,
  StyledCartPreviewContainer,
  StyledCartPreviewFooter,
  StyledCartPreviewFooterRow,
  StyledCartPreviewGoToCartButton,
  StyledCartPreviewGoToCartLink,
  StyledCartPreviewHeader,
  StyledCartPreviewHeaderHeading,
  StyledCartPreviewHeaderInnerWrapper,
  StyledSpinner,
} from '@molecules/MiniCart/Preview/CartPreview.styles';
import Icon from '@atoms/Icon/Icon';
import PopoverMenu from '@molecules/PopoverMenu/PopoverMenu';
import PopoverMenuItem from '@molecules/PopoverMenu/PopoverMenuItem/PopoverMenuItem';
import { trackCartPreviewEmptyState } from '@helpers/analyticsHelpers/trackCartPreview';
import EllipsisVertical from '@icons/menu-secondary.svg';
import useCart from '@hooks/useCart';
import DynamicNamespaces from 'next-translate/DynamicNamespaces';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import EmptyCart from '@molecules/EmptyCart/EmptyCart';
import AddToListComponent from '@organisms/AddToListComponent/AddToListComponent';
import AddToList from '@icons/add-to-list.svg';

type Props = {
  headerRef: RefObject<HTMLDivElement>;
};

const CartPreview = ({ headerRef }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shadowFactor, setShadowFactor] = useState(0);
  const [isEmptyCartModalVisible, setEmptyCartModalVisibility] = useState(false);
  const [addToListModalOpen, setAddToListModalOpen] = useState(false);

  const { cart, isCartEmpty, hasFetchedCart } = useCart();
  const { t } = useTranslation();
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const productEntriesForWishlist = makeProductEntries(cart?.products);

  const cartPreviewRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLElement>(null);

  const queryParam = { from: LoginQueryParams.CART };

  const onGoToCart = (e: MouseEvent) => {
    if (isCartEmpty) e.preventDefault();
  };

  const scrollHandler = () => {
    const offsetHeight = (headerRef.current?.offsetHeight || 60) + Config.HEIGHTS.STICKY_TOOLBAR;
    if (offsetHeight && cartPreviewRef.current) {
      if (window.pageYOffset >= offsetHeight - Config.HEIGHTS.STICKY_TOOLBAR) {
        cartPreviewRef.current.style.height = `calc(100vh - ${Config.HEIGHTS.STICKY_TOOLBAR}px)`;
      } else {
        cartPreviewRef.current.style.height = `calc(100vh - ${offsetHeight - window.pageYOffset}px)`;
      }
    }
  };

  useResizeObserver<HTMLDivElement>(headerRef, () => {
    scrollHandler();
  });

  const productListScrollHandler = (el: HTMLElement) => {
    // Calculate number of pixels left to be scrolled until the bottom of the element
    const px = el.scrollHeight - el.scrollTop - el.clientHeight;
    setShadowFactor(Math.min(50, px) / 50);
  };

  useResizeObserver<HTMLElement>(outerRef, () => {
    outerRef.current && productListScrollHandler(outerRef.current);
  });

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (outerRef.current) {
      productListScrollHandler(outerRef.current);
    }
  }, [cart?.products]);

  useEffect(() => {
    if (outerRef.current) {
      const current = outerRef.current;
      current.addEventListener('scroll', () => productListScrollHandler(current));
      return () => {
        current.removeEventListener('scroll', () => productListScrollHandler(current));
      };
    }
  }, [outerRef]);

  const openEmptyCartModal = () => {
    setEmptyCartModalVisibility(true);
  };

  useEffect(() => {
    // Focus on first button in the minicart when it opens, for A11y
    if (isCartPreviewOpen) {
      const firstButton = cartPreviewRef?.current?.querySelector('button');
      firstButton?.focus();
    }
  }, [isCartPreviewOpen]);

  useEffect(() => {
    if (isCartPreviewOpen && hasFetchedCart && !cart?.products?.length) {
      trackCartPreviewEmptyState();
    }
  }, [cart, isCartPreviewOpen, hasFetchedCart]);

  return (
    <>
      <EmptyCartModal
        visible={isEmptyCartModalVisible}
        setVisibility={setEmptyCartModalVisibility}
        tracking={{ actionFieldList: 'minicart', category: 'minicart', action: 'removed_all_products_from_cart' }}
      />
      <StyledCartPreview
        data-testid="cart-preview"
        ref={cartPreviewRef}
        isOpen={isCartPreviewOpen}
        role="complementary"
        aria-label={t('cartpreview:minicartLabel')}
        backdropVisible={addToListModalOpen}
      >
        {isCartPreviewOpen && (
          <DynamicNamespaces namespaces={['cartpreview', 'cartpage']}>
            <StyledCartPreviewHeader>
              <StyledCartPreviewHeaderInnerWrapper>
                <StyledCartPreviewHeaderHeading variant="h2" size="small">
                  <Trans i18nKey="cartpreview:cart->heading" values={{ count: cart?.totalUnitCount || 0 }} />
                </StyledCartPreviewHeaderHeading>
                <StyledAddToListButton
                  theme="transparent"
                  onClick={() => setAddToListModalOpen(true)}
                  disabled={productEntriesForWishlist?.length === 0}
                  data-testid="add-to-list-button"
                  aria-label={t('cartpreview:cart->saveToList')}
                >
                  <Icon
                    svg={AddToList}
                    size={20}
                    color={productEntriesForWishlist?.length === 0 ? 'darkerGray' : 'black'}
                  />
                </StyledAddToListButton>
                <AddToListComponent
                  tracking={{ parent: 'minicart' }}
                  addToListCallback={() => {
                    trackShoppingList({ action: 'current', parent: 'minicart' });
                  }}
                  createNewListCallback={() => {
                    trackShoppingList({ action: 'new', parent: 'minicart' });
                  }}
                  queryParamsIfNotLoggedIn={queryParam}
                  productEntries={productEntriesForWishlist}
                  onClose={() => setAddToListModalOpen(false)}
                  isOpen={addToListModalOpen}
                />

                <Button
                  theme="transparent"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen(true);
                  }}
                  data-testid="cartpreview-menu"
                  aria-label={t('cartpreview:openMenuLabel')}
                >
                  <Icon svg={EllipsisVertical} size={20} />
                </Button>

                <PopoverMenu open={isMenuOpen} handleClose={() => setIsMenuOpen(false)}>
                  <PopoverMenuItem onClick={openEmptyCartModal} disabled={!cart?.products?.length}>
                    <Trans i18nKey="cartpreview:cart->clear" />
                  </PopoverMenuItem>
                </PopoverMenu>
              </StyledCartPreviewHeaderInnerWrapper>
            </StyledCartPreviewHeader>
            <StyledCartPreviewBodyWrapper ref={outerRef}>
              {hasFetchedCart ? (
                <>
                  {cart?.products?.length ? (
                    <div>
                      {cart.products.map((product) => (
                        <ProductListItem product={product} key={`cart-row-${product.name}-${product.code}`} />
                      ))}
                    </div>
                  ) : (
                    <StyledCartPreviewContainer>
                      <EmptyCart fromCartPage={false} />
                    </StyledCartPreviewContainer>
                  )}
                </>
              ) : (
                <StyledCartPreviewContainer>
                  <div>
                    <Text type="subhead" size="small">
                      <Trans i18nKey="cartpage:loading->title" />
                    </Text>
                    <StyledSpinner color="black" size={32} />
                  </div>
                  <Text type="body" size="small">
                    <Trans i18nKey="cartpage:loading->body" />
                  </Text>
                </StyledCartPreviewContainer>
              )}
            </StyledCartPreviewBodyWrapper>
            <StyledCartPreviewFooter shadowFactor={shadowFactor}>
              <StyledCartPreviewFooterRow>
                <Text type="body" size="small">
                  <Trans i18nKey="cartpreview:cart->totalPrice" />
                </Text>
                <Text type="price">{cart?.subTotalWithDiscounts || '0,00 kr'}</Text>
              </StyledCartPreviewFooterRow>
              <StyledCartPreviewFooterRow>
                <Text type="body" size="small">
                  <Trans i18nKey="cartpreview:cart->youSave" />
                </Text>

                <Text type="price" size="small" color="red">
                  {cart?.totalPromotionDiscount || '0,00 kr'}
                </Text>
              </StyledCartPreviewFooterRow>

              <StyledCartPreviewGoToCartLink onClick={onGoToCart} href={paths.CART} variant="secondary" prefetch={false}>
                <StyledCartPreviewGoToCartButton disabled={!cart?.products?.length}>
                  <Trans i18nKey="cartpreview:cart->goToCheckout" />
                </StyledCartPreviewGoToCartButton>
              </StyledCartPreviewGoToCartLink>
            </StyledCartPreviewFooter>
          </DynamicNamespaces>
        )}
      </StyledCartPreview>
    </>
  );
};

export default CartPreview;
