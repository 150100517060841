import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Heading from '@atoms/Heading/Heading';
import Config from '@config';

export const StyledEmptyCart = styled.div<{ inCartPage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${rem(500)};
  margin: auto;

  ${(props) =>
    props.inCartPage &&
    css`
      // All these numbers should align with GridContainer margins/paddings
      padding: ${rem(60)} 0 ${rem(40)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: ${rem(40)} 0 ${rem(48)};
      }

      ${Config.MEDIA.FROM_DESKTOP} {
        padding: 0 0 ${rem(40)};
      }

      ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
        margin-top: ${rem(80)};
        padding-bottom: ${rem(100)};
      }
    `}
`;
export const StyledHeading = styled(Heading)`
  margin-bottom: ${rem(4)};
  text-align: center;

  ${Config.MEDIA.FROM_DESKTOP} {
    margin-bottom: ${rem(24)};
  }
`;
export const StyledTextWrapper = styled.div`
  text-align: center;
  margin-top: ${rem(50)};
`;
